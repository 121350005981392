<template>
  <div v-if="viewFlag">
    <h2 class="content_title">{{ $t('msg.ONEX010P090.002') }}</h2> <!-- B/L HEADER
    -->
    <table class="tbl_col" style="table-layout: fixed;">
      <colgroup>
        <col style="width:14%">
        <col style="width:43%">
        <col style="width:43%">
      </colgroup>
      <thead>
        <tr>
          <th>{{ $t('msg.ONEX010P090.003') }}</th> <!-- Item -->
          <th>{{ $t('msg.ONEX010P090.004') }}</th> <!-- Originally Made Out -->
          <th>{{ $t('msg.ONEX010P090.005') }}</th> <!-- Should Be Read -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="text_left">{{ item.caNm }}</td>
          <td class="text_left">
            <template v-if="item.caCd === '012' || item.caCd === '022' || item.caCd === '032'">
              <textarea v-model="item.contOrg" rows="5" readonly style="font-size:11px"></textarea>
            </template>
            <template v-else>
              {{ item.contOrg }}
            </template>
          </td>
          <td class="text_left">
            <template v-if="item.caCd === '012' || item.caCd === '022' || item.caCd === '032'">
              <textarea v-model="item.contShd" rows="5" readonly style="font-size:11px"></textarea>
            </template>
            <template v-else>
              {{ item.contShd }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <br>
  </div>
</template>

<script>
export default {
  name: 'ShippingLogBLDetailHeaderPop',
  props: {
    mainData: Object
  },
  data () {
    return {
      viewFlag: '',
      items: [],
      statusNm: '',
      perNm: '',
      pcNm: ''
    }
  },
  created: function () {
    console.log('created')
  },
  mounted: function () {
    console.log('B/L Detail header Pop')
    this.init()
  },
  watch: {
    mainData () {
      this.init()
    }
  },
  methods: {
    async init () {
      const blHeader = this.mainData.blHeader
      if (blHeader && blHeader.length > 0) {
        this.items = blHeader
        this.viewFlag = true
      } else {
        this.viewFlag = false
      }
    }
  }
}
</script>
