var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.viewFlag
    ? _c("div", [
        _c("h2", { staticClass: "content_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.002"))),
        ]),
        _vm._v(" "),
        _c(
          "table",
          { staticClass: "tbl_col", staticStyle: { "table-layout": "fixed" } },
          [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.003")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.004")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.005")))]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.items, function (item, index) {
                return _c("tr", { key: index }, [
                  _c("td", { staticClass: "text_left" }, [
                    _vm._v(_vm._s(item.caNm)),
                  ]),
                  _c(
                    "td",
                    { staticClass: "text_left" },
                    [
                      item.caCd === "012" ||
                      item.caCd === "022" ||
                      item.caCd === "032"
                        ? [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.contOrg,
                                  expression: "item.contOrg",
                                },
                              ],
                              staticStyle: { "font-size": "11px" },
                              attrs: { rows: "5", readonly: "" },
                              domProps: { value: item.contOrg },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(item, "contOrg", $event.target.value)
                                },
                              },
                            }),
                          ]
                        : [_vm._v(" " + _vm._s(item.contOrg) + " ")],
                    ],
                    2
                  ),
                  _c(
                    "td",
                    { staticClass: "text_left" },
                    [
                      item.caCd === "012" ||
                      item.caCd === "022" ||
                      item.caCd === "032"
                        ? [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.contShd,
                                  expression: "item.contShd",
                                },
                              ],
                              staticStyle: { "font-size": "11px" },
                              attrs: { rows: "5", readonly: "" },
                              domProps: { value: item.contShd },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(item, "contShd", $event.target.value)
                                },
                              },
                            }),
                          ]
                        : [_vm._v(" " + _vm._s(item.contShd) + " ")],
                    ],
                    2
                  ),
                ])
              }),
              0
            ),
          ]
        ),
        _c("br"),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "14%" } }),
      _c("col", { staticStyle: { width: "43%" } }),
      _c("col", { staticStyle: { width: "43%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }